
import React from 'react';
import Header from '../../header/Header';

function Plan() {
    return (
      <>
      {/* header component */}

      <div className='bg-dark'>
        <Header></Header>
      </div>
      
      {/* banner section*/}

        <h1 className="page-title text-center py-5 my-5">
            Plans Page
        </h1>
      </>
    );
}
export default  Plan