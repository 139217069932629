import React from 'react';
import Image4 from '../../images/Image4@2x.png';
import Group3 from '../../images/Group3.svg';
import Group22 from '../../images/Group22.svg';
import NewNo from '../../images/new-no.svg';
import SimCard from '../../images/sim-card.svg';
import ActiveAccount from '../../images/active-account.svg';

function JoinEasy() {
  return (
    <>
      <section className="join-is-easy">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <img src={Image4} alt="images" className="img-fluid" />
            </div>
            <div className="col-md-7">
              <div className="joining">
                <h2>Joining is Easy</h2>
                <img src={Group3} alt="icon" className="img-fluid blacksvg" />
                <p>Simply order your Raza SIM card here. Well send it to you by courier, pop it in your phone and you’re
                  good to Raza! Enjoy unlimited 4G speeds with 99% 4G covearge</p>
              </div>
              <div className="row  right-line">
                <div className="col-md-3">
                  <img src={Group22} alt="icon" className="img-fluid" />
                  <h4>Select your SIM Only Plan </h4>
                </div>
                <div className="col-md-3">
                  <img src={SimCard} alt="icon" className="img-fluid" />
                  <h4>Order your SIM Card</h4>
                </div>
                <div className="col-md-3">
                  <img src={NewNo} alt="icon" className="img-fluid" />
                  <h4>Move or use your new number </h4>
                </div>
                <div className="col-md-3">
                  <img src={ActiveAccount} alt="icon" className="img-fluid" />
                  <h4>Activate your account</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default JoinEasy