import React from 'react';
import {Link} from 'react-router-dom';
import {navLinks} from './HeaderMenu';
import Logo from '../images/logo.svg';
import ActiveIcon from '../images/active.svg';
import ArrowIcon from '../images/arrow-log.svg';
import CartIcon from '../images/cart-icon.svg';
import MenuIcon from '../images/menu-icon.svg';
import './Header.css';

function Header() {


  return (
    <>
      <div className="full-subtop">
        <div className="container-fluid">
          <div className="row d-flex">
            <div className="col-md-7 d-flex align-items-center">
              <p>New phone and Service bundles from $15/Mo <Link to="#" className="lean-m">Learn More</Link></p>
            </div>
            <div className="col-md-3">
              <div className="form-group has-search">
                <span className="fa fa-search form-control-feedback"></span>
                <input type="text" className="form-control" placeholder="Iam Looking for…" />
              </div>
            </div>
            <div className="col-md-2 d-flex align-items-center rightmov">
              <Link to="#" className="anchor-tag">Reviews</Link> | <Link to="#" className="anchor-tag"> Why Raza</Link></div>
          </div>
        </div>
      </div>

      <header>
        <div className="container-fluid">
          <div className="row">
            <div className="align">
              <nav className="navbar navbar-expand-sm navbar-darks">
                <Link className="navbar-brand" to="/">
                  <img src={MenuIcon} alt="icon" className="menu-icon" />
                  <img src={Logo} alt="logo" />
                </Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent" aria-expanded="false"
                  aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav mr-auto navi-part">
                    {navLinks.map((link, index,) => {
                      return (
                        <>
                          {(link.subMenu.length === 0) ?
                            (
                              <li className="nav-item active">
                                <Link className="nav-link" to={link.path}>{link.name}
                                  <span className="sr-only">(current)</span>
                                </Link>
                              </li>
                            ) :
                            <li className="nav-item dropdown">
                              <Link className="nav-link dropdown-toggle" to={link.path} id={link.name} role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              {link.name}
                              </Link>
                              <div className="dropdown-menu" aria-labelledby={link.name}>                       
                                {link.subMenu.map((subMenuItems) => {
                                  return (
                                    <Link className="dropdown-item" to={subMenuItems.path}>{subMenuItems.name}</Link>
                                  );
                                })}
                              </div>
                            </li>
                          }
                        </>
                      );
                    })}
                  </ul>
                </div>
              </nav>
              <Link to="#" className="active-btn"><img src={ActiveIcon} alt="icon" /> Activate</Link>
              <Link to="#" className="login-btn"><img src={ArrowIcon} alt="icon" />Login</Link>
              <span className="cart-bg"><img src={CartIcon} alt="icon" /></span>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header