export const navLinks = [
    {
        name: "Plans",
        path: "plan",
        subMenu:[]
    },
    {
        name: "Phones",
        path: "#",
        subMenu: [
            {
                name: "Phone sub menu",
                path: "#",
            },
            {
                name: "Phone sub menu",
                path: "#",
            },
        ]
    },
    {
        name: "Coverage",
        path: "#",
        subMenu:[]
    },
    {
        name: "How it Works",
        path: "#",
        subMenu:[]
    },
    {
        name: "Video Help",
        path: "#",
        subMenu:[]
    },
    {
        name: "Help",
        path: "#",
        subMenu: [
            {
                name: "Help sub menu",
                path: "#",
            },
            {
                name: "Help sub menu",
                path: "#",
            },
        ]
    },
];