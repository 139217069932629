import './App.css';
import Home from './components/pages/home/Home';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Plan from './components/pages/plans/plan';

function App() {
  console.log = console.info = console.warn = console.error = () => { };
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route exact path="/plan" element={<Plan/>} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
