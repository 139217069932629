import React from 'react';
import Header from '../../header/Header';
import CustomerOffer from '../customer-offer/CustomerOffer';
import JoinEasy from '../join-easy/JoinEasy';
import Banner from '../banner/Banner';
import './Home.css';

function Home() {
  return (
    <>
      {/* header component */}

      <Header></Header>
      
      <main>
        {/* banner section*/}

        <Banner></Banner>

        {/* customer-offer section */}

        <CustomerOffer></CustomerOffer>

        {/* join-easy section */}

        <JoinEasy></JoinEasy>
      </main>
    </>
  );
}

export default Home