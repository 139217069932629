import React from 'react';
import banner from '../../images/top-banner.jpg';

function Banner() {
  return (
    <>
      <section className="banner">
        <div className="top-bannerimg">
          <img src={banner} className="img-fluid" alt=" banner" />
          <button type="button" className="btn btn-buy">Buy Now</button>
        </div>
      </section>
    </>
  );
}

export default Banner