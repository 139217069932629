import React from 'react';
import {Link} from 'react-router-dom';
import HeadingLine from '../../images/heading-line.svg';
import Shape from '../../images/shape.svg';
import Greentick from '../../images/greentick.svg';
import Group893 from '../../images/Group893.svg';

function CustomerOffer() {
  return (
    <>
      <section className="customer-offer">
        <div className="container">
          <div className="row">
            <div className="heading">
              <h2>New Customer Offer</h2>
              <p>Our New Customer Offer lets you try us out for <br /> 3 Months at our lowest possible price.</p>
              <img src={HeadingLine} alt="icon" className="img-fluid wmanag-line" />
            </div>
            <div className="nav-tabs-wrapper">
              <ul className="nav nav-tabs dragscroll horizontal">
                <li className="nav-item"><Link className="nav-link active" data-toggle="tab" to="#tabA">1</Link></li>
                <li className="nav-item"><Link className="nav-link" data-toggle="tab" to="#tabB">2</Link></li>
                <li className="nav-item"><Link className="nav-link" data-toggle="tab" to="#tabC">3</Link></li>
                <li className="nav-item"><Link className="nav-link" data-toggle="tab" to="#tabD">4</Link></li>
              </ul>
            </div>
            <div className="tab-content w-100">
              <div className="tab-pane fade show active" id="tabA">
                <div className="row flex-align">
                  <div className="col-md-3">
                    <div className="data-box">
                      <p className="g5">5G • 4G LTE DATA</p>
                      <h4 className="gb-m">1GB<sub>/MO</sub></h4>
                      <div className="relative">
                        <img src={Shape} alt="icon" className="shape-gb" />
                        <span className="compare">
                          <Link to="#">Compare Raza vs AT&T & Verizon</Link>
                        </span>
                      </div>
                      <div className="dollar-text">
                        <sup>$</sup>5<sub>/MO</sub>
                      </div>
                      <div className="points-link">
                        <p><img src={Greentick} alt="icon" /> Unlimited talk & text</p>
                        <p><img src={Greentick} alt="icon" /> Nationwide coverage</p>
                        <p><img src={Greentick} alt="icon" /> Uses 5G or 4G LTE</p>
                      </div>
                      <button type="button" className="btn btn-detail">VIEW DETAILS</button>
                      <p className="servi-m">$45 for 3 months of service</p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="data-box">
                      <p className="g5">5G • 4G LTE DATA</p>
                      <h4 className="gb-m">2GB<sub>/MO</sub></h4>
                      <div className="relative">
                        <img src={Shape} alt="icon" className="shape-gb" />
                        <span className="compare">
                          <Link to="#">Compare Raza vs AT&T & Verizon</Link></span>
                      </div>
                      <div className="dollar-text">
                        <sup>$</sup>8<sub>/MO</sub>
                      </div>
                      <div className="points-link">
                        <p><img src={Greentick} alt="icon" /> Unlimited talk & text</p>
                        <p><img src={Greentick} alt="icon" /> Nationwide coverage</p>
                        <p><img src={Greentick} alt="icon" /> Uses 5G or 4G LTE</p>
                      </div>
                      <button type="button" className="btn btn-detail">VIEW DETAILS</button>
                      <p className="servi-m">$60 for 3 months of service</p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="data-box">
                      <p className="g5">5G • 4G LTE DATA</p>
                      <h4 className="gb-m">3GB<sub>/MO</sub></h4>
                      <div className="relative">
                        <img src={Shape} alt="icon" className="shape-gb" />
                        <span className="compare">
                          <Link to="#">Compare Raza vs AT&T & Verizon</Link></span>
                      </div>
                      <div className="dollar-text">
                        <sup>$</sup>10<sub>/MO</sub>
                      </div>
                      <div className="points-link">
                        <p><img src={Greentick} alt="icon" /> Unlimited talk & text</p>
                        <p><img src={Greentick} alt="icon" /> Nationwide coverage</p>
                        <p><img src={Greentick} alt="icon" /> Uses 5G or 4G LTE</p>
                      </div>
                      <button type="button" className="btn btn-detail">VIEW DETAILS</button>
                      <p className="servi-m">$60 for 3 months of service</p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="data-box">
                      <h4 className="gb-m font38">UNLIMITED</h4>
                      <div className="relative">
                        <img src={Shape} alt="icon" className="shape-gb" />
                        <span className="compare">
                          <Link to="#">Compare Raza vs AT&T & Verizon</Link></span>
                      </div>
                      <div className="dollar-text">
                        <sup>$</sup>30<sub>/MO</sub>
                      </div>
                      <div className="points-link">
                        <p><img src={Greentick} alt="icon" /> Unlimited talk & text</p>
                        <p><img src={Greentick} alt="icon" /> Nationwide coverage</p>
                        <p><img src={Greentick} alt="icon" /> Uses 5G or 4G LTE</p>
                      </div>
                      <div className="bot">
                        <button type="button" className="btn btn-detail">VIEW DETAILS</button>
                        <p className="servi-m">$60 for 3 months of service</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="tabB">
                <div className="row justify-content-center">
                  <div className="col-md-3">
                    <div className="data-box">
                      <p className="g5">5G • 4G LTE DATA</p>
                      <h4 className="gb-m">1GB<sub>/MO</sub></h4>
                      <div className="relative">
                        <img src={Shape} alt="icon" className="shape-gb" />
                        <span className="compare">
                          <Link to="#">Compare Raza vs AT&T & Verizon</Link></span>
                      </div>
                      <div className="dollar-text">
                        <sup>$</sup>5<sub>/MO</sub>
                      </div>
                      <div className="points-link">
                        <p><img src={Greentick} alt="icon" /> Unlimited talk & text</p>
                        <p><img src={Greentick} alt="icon" /> Nationwide coverage</p>
                        <p><img src={Greentick} alt="icon" /> Uses 5G or 4G LTE</p>
                      </div>
                      <button type="button" className="btn btn-detail">VIEW DETAILS</button>
                      <p className="servi-m">$45 for 3 months of service</p>
                    </div>
                  </div>


                  <div className="col-md-3">
                    <div className="data-box">
                      <p className="g5">5G • 4G LTE DATA</p>
                      <h4 className="gb-m">3GB<sub>/MO</sub></h4>
                      <div className="relative">
                        <img src={Shape} alt="icon" className="shape-gb" />
                        <span className="compare">
                          <Link to="#">Compare Raza vs AT&T & Verizon</Link></span>
                      </div>
                      <div className="dollar-text">
                        <sup>$</sup>10<sub>/MO</sub>
                      </div>
                      <div className="points-link">
                        <p><img src={Greentick} alt="icon" /> Unlimited talk & text</p>
                        <p><img src={Greentick} alt="icon" /> Nationwide coverage</p>
                        <p><img src={Greentick} alt="icon" /> Uses 5G or 4G LTE</p>
                      </div>
                      <button type="button" className="btn btn-detail">VIEW DETAILS</button>
                      <p className="servi-m">$60 for 3 months of service</p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="data-box">
                      <h4 className="gb-m font38">UNLIMITED</h4>
                      <div className="relative">
                        <img src={Shape} alt="icon" className="shape-gb" />
                        <span className="compare">
                          <Link to="#">Compare Raza vs AT&T & Verizon</Link></span>
                      </div>
                      <div className="dollar-text">
                        <sup>$</sup>30<sub>/MO</sub>
                      </div>
                      <div className="points-link">
                        <p><img src={Greentick} alt="icon" /> Unlimited talk & text</p>
                        <p><img src={Greentick} alt="icon" /> Nationwide coverage</p>
                        <p><img src={Greentick} alt="icon" /> Uses 5G or 4G LTE</p>
                      </div>
                      <div className="bot">
                        <button type="button" className="btn btn-detail">VIEW DETAILS</button>
                        <p className="servi-m">$60 for 3 months of service</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="tabC">
                <div className="row flex-align">
                  <div className="col-md-3">
                    <div className="data-box">
                      <p className="g5">5G • 4G LTE DATA</p>
                      <h4 className="gb-m">1GB<sub>/MO</sub></h4>
                      <div className="relative">
                        <img src={Shape} alt="icon" className="shape-gb" />
                        <span className="compare">
                          <Link to="#">Compare Raza vs AT&T & Verizon</Link></span>
                      </div>
                      <div className="dollar-text">
                        <sup>$</sup>5<sub>/MO</sub>
                      </div>
                      <div className="points-link">
                        <p><img src={Greentick} alt="icon" /> Unlimited talk & text</p>
                        <p><img src={Greentick} alt="icon" /> Nationwide coverage</p>
                        <p><img src={Greentick} alt="icon" /> Uses 5G or 4G LTE</p>
                      </div>
                      <button type="button" className="btn btn-detail">VIEW DETAILS</button>
                      <p className="servi-m">$45 for 3 months of service</p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="data-box">
                      <p className="g5">5G • 4G LTE DATA</p>
                      <h4 className="gb-m">2GB<sub>/MO</sub></h4>
                      <div className="relative">
                        <img src={Shape} alt="icon" className="shape-gb" />
                        <span className="compare">
                          <Link to="#">Compare Raza vs AT&T & Verizon</Link></span>
                      </div>
                      <div className="dollar-text">
                        <sup>$</sup>8<sub>/MO</sub>
                      </div>
                      <div className="points-link">
                        <p><img src={Greentick} alt="icon" /> Unlimited talk & text</p>
                        <p><img src={Greentick} alt="icon" /> Nationwide coverage</p>
                        <p><img src={Greentick} alt="icon" /> Uses 5G or 4G LTE</p>
                      </div>
                      <button type="button" className="btn btn-detail">VIEW DETAILS</button>
                      <p className="servi-m">$60 for 3 months of service</p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="data-box">
                      <p className="g5">5G • 4G LTE DATA</p>
                      <h4 className="gb-m">3GB<sub>/MO</sub></h4>
                      <div className="relative">
                        <img src={Shape} alt="icon" className="shape-gb" />
                        <span className="compare">
                          <Link to="#">Compare Raza vs AT&T & Verizon</Link></span>
                      </div>
                      <div className="dollar-text">
                        <sup>$</sup>10<sub>/MO</sub>
                      </div>
                      <div className="points-link">
                        <p><img src={Greentick} alt="icon" /> Unlimited talk & text</p>
                        <p><img src={Greentick} alt="icon" /> Nationwide coverage</p>
                        <p><img src={Greentick} alt="icon" /> Uses 5G or 4G LTE</p>
                      </div>
                      <button type="button" className="btn btn-detail">VIEW DETAILS</button>
                      <p className="servi-m">$60 for 3 months of service</p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="data-box">
                      <h4 className="gb-m font38">UNLIMITED</h4>
                      <div className="relative">
                        <img src={Shape} alt="icon" className="shape-gb" />
                        <span className="compare">
                          <Link to="#">Compare Raza vs AT&T & Verizon</Link></span>
                      </div>
                      <div className="dollar-text">
                        <sup>$</sup>30<sub>/MO</sub>
                      </div>
                      <div className="points-link">
                        <p><img src={Greentick} alt="icon" /> Unlimited talk & text</p>
                        <p><img src={Greentick} alt="icon" /> Nationwide coverage</p>
                        <p><img src={Greentick} alt="icon" /> Uses 5G or 4G LTE</p>
                      </div>
                      <div className="bot">
                        <button type="button" className="btn btn-detail">VIEW DETAILS</button>
                        <p className="servi-m">$60 for 3 months of service</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="tabD">
                <div className="row justify-content-center">
                  <div className="col-md-3">
                    <div className="data-box">
                      <p className="g5">5G • 4G LTE DATA</p>
                      <h4 className="gb-m">1GB<sub>/MO</sub></h4>
                      <div className="relative">
                        <img src={Shape} alt="icon" className="shape-gb" />
                        <span className="compare">
                          <Link to="#">Compare Raza vs AT&T & Verizon</Link></span>
                      </div>
                      <div className="dollar-text">
                        <sup>$</sup>5<sub>/MO</sub>
                      </div>
                      <div className="points-link">
                        <p><img src={Greentick} alt="icon" /> Unlimited talk & text</p>
                        <p><img src={Greentick} alt="icon" /> Nationwide coverage</p>
                        <p><img src={Greentick} alt="icon" /> Uses 5G or 4G LTE</p>
                      </div>
                      <button type="button" className="btn btn-detail">VIEW DETAILS</button>
                      <p className="servi-m">$45 for 3 months of service</p>
                    </div>
                  </div>


                  <div className="col-md-3">
                    <div className="data-box">
                      <p className="g5">5G • 4G LTE DATA</p>
                      <h4 className="gb-m">3GB<sub>/MO</sub></h4>
                      <div className="relative">
                        <img src={Shape} alt="icon" className="shape-gb" />
                        <span className="compare">
                          <Link to="#">Compare Raza vs AT&T & Verizon</Link></span>
                      </div>
                      <div className="dollar-text">
                        <sup>$</sup>10<sub>/MO</sub>
                      </div>
                      <div className="points-link">
                        <p><img src={Greentick} alt="icon" /> Unlimited talk & text</p>
                        <p><img src={Greentick} alt="icon" /> Nationwide coverage</p>
                        <p><img src={Greentick} alt="icon" /> Uses 5G or 4G LTE</p>
                      </div>
                      <button type="button" className="btn btn-detail">VIEW DETAILS</button>
                      <p className="servi-m">$60 for 3 months of service</p>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="data-box">
                      <h4 className="gb-m font38">UNLIMITED</h4>
                      <div className="relative">
                        <img src={Shape} alt="icon" className="shape-gb" />
                        <span className="compare">
                          <Link to="#">Compare Raza vs AT&T & Verizon</Link></span>
                      </div>
                      <div className="dollar-text">
                        <sup>$</sup>30<sub>/MO</sub>
                      </div>
                      <div className="points-link">
                        <p><img src={Greentick} alt="icon" /> Unlimited talk & text</p>
                        <p><img src={Greentick} alt="icon" /> Nationwide coverage</p>
                        <p><img src={Greentick} alt="icon" /> Uses 5G or 4G LTE</p>
                      </div>
                      <div className="bot">
                        <button type="button" className="btn btn-detail">VIEW DETAILS</button>
                        <p className="servi-m">$60 for 3 months of service</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="unlimited">Unlimited plan customers using 35GB/mo will experience lower speeds. Videos stream at 480p.
            <br /> Capable device required. Coverage not available in all areas.
            <i tabindex="0" role="button" data-toggle="popover" data-trigger="focus" title="Disclosure"
              data-content="Unlimited on handset & network only. Hotspot capped at 5GB. Taxes and fees apply. https://www.raza.com/plan-terms-and-conditions/"
              className="fas fa-question-circle popover-dismiss"></i>
          </p>
          <button type="button" className="btn framephone">
            <img src={Group893} alt="icon" />
            <span className="topm"> View All Plans</span>
          </button>
        </div>
      </section>
    </>
  );
}

export default CustomerOffer